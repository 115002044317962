import wiserman from "../../assets/wiserman.png"
import puddle from "../../assets/puddle.png"
import fleurs from "../../assets/fleurs.png"
import ghost from "../../assets/ghost.png"
import river from "../../assets/river.png"
import truth from "../../assets/truth.png"
import weird from "../../assets/weird.png"
import haiku from "../../assets/haiku.png"
import hilton from "../../assets/hilton.png"
import you from "../../assets/you.png"
import beginning from "../../assets/beginning.png"
import understandings from "../../assets/understandings.png"
export const svgs = {wiserman,puddle,fleurs,ghost,river,haiku,hilton,you,understandings,beginning,truth,weird}
export default svgs
